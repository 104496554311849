
import { GiphyFetch } from '@giphy/js-fetch-api';
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import image1 from "assets/images/products/product-details-1.jpg";
import MDAlert from "components/MDAlert";
import MDAvatar from "components/MDAvatar";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
// Material Dashboard 2 PRO React components
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { setOpenConfigurator, useMaterialUIController, setCustomConfig, setOrderId } from "context";
import Configurator from "examples/Configurator";
import Footer from "examples/Footer";
// Material Dashboard 2 PRO React examples
import DefaultItem from "examples/Items/DefaultItem";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Rating from '@mui/material/Rating';

import Firebase from "firebase";
import reportsLineChartData from "layouts/dashboards/analytics/data/reportsLineChartData";
import * as React from 'react';
import { useEffect, useState } from "react";

import ImgsViewer from "react-images-viewer";
import { Link } from "react-router-dom";
import { useCart } from "react-use-cart";
import "core-js/actual/array/group-by";
const SMBase64 = require('smbase64')
let base64 = new SMBase64()

const firebaseConfig = {
  apiKey: "AIzaSyDSaYuHlpFznfk_nhTcitcoGG-8QUGK1ec",
  authDomain: "onroad3-e593b.firebaseapp.com",
  databaseURL: "https://onroad3-e593b-default-rtdb.firebaseio.com",
  projectId: "onroad3-e593b",
  storageBucket: "onroad3-e593b.appspot.com",
  messagingSenderId: "587064425563",
}
Firebase.initializeApp(firebaseConfig);
const gf = new GiphyFetch('d6r2Pnp7hZLTR6DpOxLDr4fS1H15BBgC')

function Home() {

  let trackID, pathName;
  pathName = window?.location?.pathname
  if(pathName == '/' || !pathName){
  window?.location?.replace('https://onroad.app');
  }else{
    trackID = pathName.substring(1)
    if(!trackID) {
      window?.location?.replace('https://onroad.app');
    }
  }

  const [singnedIn, setSignedIn] = useState(false);
  const [invalidTrackID, setInvalidTrackID] = useState(false);
  const [deliveryStatus, setDeliveryStatus] = useState(false);
  const [deliveryProof, setDeliveryProof] = useState();
  const [trackingDetails, setTrackingDetails] = useState(null);
  const [trackViewConfig, setTrackViewConfig] = useState(null);
  const google = window.google;
  const directionsService = new google.maps.DirectionsService();

  const [giphyEmbedURL, setGiphyEmbedURL] = React.useState();  
  const [controller, dispatch] = useMaterialUIController();

//   const [newCardForm, setNewCardForm] = React.useState(false);
  const [deliveryReviewStars, setDeliveryReviewStars] = React.useState(5);
  const [tipamount, setTipamount] = React.useState();
  const [deliveryReviewNote, setDeliveryReviewNote] = React.useState("Thank you very much for the service");
  const labels = {
    0.5: 'Useless',
    1: 'Useless+',
    1.5: 'Poor',
    2: 'Poor+',
    2.5: 'Ok',
    3: 'Ok+',
    3.5: 'Good',
    4: 'Good+',
    4.5: 'Excellent',
    5: 'Excellent+',
  };
  const [hover, setHover] = React.useState(-1);
  const [recorded, setRecorded] = React.useState(false);

  const mapRef = React.useRef(null);


  const [imgsViewer, setImgsViewer] = React.useState(false);
  const [imgsViewerCurrent, setImgsViewerCurrent] = React.useState(0);
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
    orderId
  } = controller;

  const openImgsViewer = () => setImgsViewer(true);
  const closeImgsViewer = () => setImgsViewer(false);
  const imgsViewerNext = () => setImgsViewerCurrent(imgsViewerCurrent + 1);
  const imgsViewerPrev = () => setImgsViewerCurrent(imgsViewerCurrent - 1);
  const handleConfiguratorOpen = () => {
    setOpenConfigurator(dispatch, !openConfigurator);
  }
  const [storeConfig, setStoreConfig] = React.useState();
  const [menuPreviewImages, setMenuPreviewImages] = React.useState();

  const [deliveryGuyLocation, setDeliveryGuyLocation] = React.useState(null);
  const [destinationLocation, setDestinationLocation] = React.useState(null);
  const [isCustomerAdjustedLocation, setIsCustomerAdjustedLocation] = React.useState(null);
  const [deliveryGuyLocationLiveTracking, setDeliveryGuyLocationLiveTracking] = React.useState(null);
  const [markerRef, setMarkerRef] = React.useState(null);
  const [chatMessages, setChatMessages] = React.useState(null);
  const [remainingTime, setRemainingTime] = React.useState(0);
  const [remainingDistance, setRemainingDistance] = React.useState(0);
  const [bottomTabValue, setBottomTabValue] = React.useState(0);
  const mapStyles = {}
  const pinMarkerSVG =
  "M10 3.5C10 4.70948 9.14112 5.71836 8 5.94999V13.5C8 13.7761 7.77614 14 7.5 14C7.22386 14 7 13.7761 7 13.5V5.94999C5.85888 5.71836 5 4.70948 5 3.5C5 2.11929 6.11929 1 7.5 1C8.88071 1 10 2.11929 10 3.5Z";
  const squareMarkerSVG =
  "M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2z";

  useEffect(() => {
      if(singnedIn){
        return;
      }
      Firebase.auth().signInAnonymously()
      .then(() => {
        setSignedIn(true)
      })
      .catch((error) => {
        setSignedIn(false)
        var errorCode = error.code;
        var errorMessage = error.message;
      });
  }, []);

  useEffect(() => {
    if(trackID){
      setOrderId(dispatch, trackID)
    }
   
  }, [trackID]);
  
  useEffect(async () => {
    if(singnedIn){
      const isValid = await Firebase.database().ref(`/tracking/delivery/${trackID}/address`).once('value')
      if(!isValid.val()){
        setInvalidTrackID(true)
      }
      // Stop listening for updates when no longer required
    }
  }, [singnedIn]);


  useEffect(() => {
    if(singnedIn){
      const onChildAdd = Firebase.database()
        .ref(`/tracking/delivery/${trackID}`)
        .once('value', snapshot => {
          const data = snapshot.val()
          if (data) {
            setTrackingDetails(data)
          } 
         
        });
      // Stop listening for updates when no longer required
      return () => Firebase.database().ref(`/tracking/delivery/${trackID}`).off('value', onChildAdd);
    }
  }, [singnedIn]);


  useEffect(() => {
    if(singnedIn){
      const onChildAdd = Firebase.database()
        .ref(`/tracking/delivery/${trackID}/deliverystatus`)
        .on('value', snapshot => {
          const data = snapshot.val()
          if (data) {
            setDeliveryStatus(data)
          } 
         
        });
      // Stop listening for updates when no longer required
      return () => Firebase.database().ref(`/tracking/delivery/${trackID}/deliverystatus`).off('value', onChildAdd);
    }
  }, [singnedIn]);

  useEffect(() => {
    if(singnedIn){
      const onChildAdd = Firebase.database()
        .ref(`/tracking/delivery/${trackID}/deliveryreview`)
        .on('value', snapshot => {
          const data = snapshot.val()
          if (data) {
            setDeliveryReviewStars(data)
          } 
         
        });
      // Stop listening for updates when no longer required
      return () => Firebase.database().ref(`/tracking/delivery/${trackID}/deliveryreview`).off('value', onChildAdd);
    }
  }, [singnedIn]);

  useEffect(() => {
    if(singnedIn){
      const onChildAdd = Firebase.database()
        .ref(`/tracking/delivery/${trackID}/tipamount`)
        .on('value', snapshot => {
          const data = snapshot.val()
          if (data) {
            setTipamount(data)
          } 
         
        });
      // Stop listening for updates when no longer required
      return () => Firebase.database().ref(`/tracking/delivery/${trackID}/tipamount`).off('value', onChildAdd);
    }
  }, [singnedIn]);

  useEffect(() => {
    if(singnedIn){
      const onChildAdd = Firebase.database()
        .ref(`/tracking/delivery/${trackID}/deliveryproof`)
        .on('value', snapshot => {
          const data = snapshot.val()
          if (data) {
            setDeliveryProof(data)
          } 
         
        });
      // Stop listening for updates when no longer required
      return () => Firebase.database().ref(`/tracking/delivery/${trackID}/deliverystatus`).off('value', onChildAdd);
    }
  }, [singnedIn]);
  
  useEffect(() => {
    if(trackingDetails && trackingDetails.customer){
      const onChildAdd = Firebase.database()
        .ref(`/trackconfig/deliverytrack/${trackingDetails.customer}`)
        .once('value', snapshot => {
          const data = snapshot.val()
          if (data) {
            setTrackViewConfig(data)
            // call 
            // tip
            // chat 
            // checklist
            // adjustLocation
            // itemdetails
          } 
        });
      return () => Firebase.database().ref(`/trackconfig/deliverytrack/${trackingDetails.customer}`).off('value', onChildAdd);
    }
  }, [trackingDetails]);
  
  useEffect(() => {
    if(trackingDetails && trackingDetails.customer){
      const onChildAdd = Firebase.database()
        .ref(`/trackconfig/deliverytrack/${trackingDetails.customer}`)
        .once('value', snapshot => {
          const data = snapshot.val()
          if (data) {
            setTrackViewConfig(data)
            // call 
            // tip
            // chat 
            // checklist
            // adjustLocation
            // itemdetails
          } 
        });
      return () => Firebase.database().ref(`/trackconfig/deliverytrack/${trackingDetails.customer}`).off('value', onChildAdd);
    }
  }, [trackingDetails]);

// support for old
// const queryParams = new URLSearchParams(window.location.search);
// const phones = queryParams.get('cid').split(",");
// const deliveryGuyPhone = phones[0]
// const destinationPhone = phones[1]
// const isIndia = destinationPhone.slice(0, -10) == '+91'
// const paymentLink = isIndia ? "https://onroadlivetracking.mojo.page/onroadlivetracking" : "https://buy.stripe.com/9AQ8Axbeze1G7ao4gk"



  React.useEffect(async () => {
    const { data } = await gf.random({ tag: 'fedex' })
    setGiphyEmbedURL(data.embed_url)
  }, [deliveryStatus=='completed']);

  React.useEffect(() => {
    if(singnedIn){
      const onChildAdd = Firebase.database()
      .ref(`/chat/${trackID}`)
      .limitToLast(100)
      .on('value', snapshot => {
        let result = [];
        snapshot.forEach(duckSnap => {
          const duck = duckSnap.val();
          result.push(duck);
        });

        if (result.length > 0) {
          setChatMessages(result);//.reverse());
        }
      });
    return () => Firebase.database().ref(`/chat/${trackID}`).off('value', onChildAdd);
    }

  }, [singnedIn])

  React.useEffect(() => {
    if (trackingDetails && trackingDetails.deliveryguyphone) {
      const onChildAdd = Firebase.database()
        .ref(`/userlivelocationtracking/${trackingDetails.deliveryguyphone}`)
        .on('value', snapshot => {
          const data = snapshot.val()
          if (data && data.latitude && data.longitude) {
            setDeliveryGuyLocationLiveTracking({
              lat: parseFloat(data.latitude),
              lng: parseFloat(data.longitude)
            })
            if(trackingDetails.addresslatlng) {
              directionsService.route(
                {
                  destination: {
                    lat: trackingDetails.addresslatlng.lat,
                    lng: trackingDetails.addresslatlng.lng,
                  },
                  origin: {
                    lat: data.latitude,
                    lng: data.longitude,
                  },
                  travelMode: "DRIVING",
                },
                (result, status) => {
                  if (status == "OK") {
                    setRemainingDistance(result.routes[0].legs[0].distance.text)
                    setRemainingTime(result.routes[0].legs[0].duration.text)
                  }
                })
            }
          }
        });
      // Stop listening for updates when no longer required
      return () => Firebase.database().ref(`/userlivelocationtracking/${trackingDetails.deliveryguyphone}`).off('value', onChildAdd);
    }
  }, [trackingDetails]);

  React.useEffect(() => {
    if (deliveryGuyLocationLiveTracking && markerRef) {
      setTimeout(() => {
        markerRef.setPosition({
          lat: deliveryGuyLocationLiveTracking.lat,
          lng: deliveryGuyLocationLiveTracking.lng
        })
        // mapRef.panTo({lat: deliveryGuyLocationLiveTracking.lat,lng: deliveryGuyLocationLiveTracking.lng});
        // mapRef.setZoom(11);
        // animatingCamera(position.coords.latitude, position.coords.longitude, position.coords.heading);
        // getPointOnPath([position.coords.latitude, position.coords.longitude])

        // TODO 
        // mapRef.setHeading(45);
      }, 1000)
    }
  }, [deliveryGuyLocationLiveTracking])
  


  
  React.useEffect(() => {
    if(!trackingDetails){
      return 
    }
    
    if(!trackingDetails.addresslatlng || !trackingDetails.deliveryguylatlng){
      return ;
    }
    let map = mapRef.current;
    const myLatlng = new google.maps.LatLng(trackingDetails.addresslatlng.lat, trackingDetails.addresslatlng.lng);
    const mapOptions = {
      zoom: 13,
      center: myLatlng,
      scrollwheel: false,
      mapTypeControl: false,
      styles: [
        {
          featureType: "administrative",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#d6e2e6",
            },
          ],
        },
        {
          featureType: "administrative",
          elementType: "geometry.stroke",
          stylers: [
            {
              color: "#cfd4d5",
            },
          ],
        },
        {
          featureType: "administrative",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#7492a8",
            },
          ],
        },
        {
          featureType: "administrative.neighborhood",
          elementType: "labels.text.fill",
          stylers: [
            {
              lightness: 25,
            },
          ],
        },
        {
          featureType: "landscape.man_made",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#dde2e3",
            },
          ],
        },
        {
          featureType: "landscape.man_made",
          elementType: "geometry.stroke",
          stylers: [
            {
              color: "#cfd4d5",
            },
          ],
        },
        {
          featureType: "landscape.natural",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#dde2e3",
            },
          ],
        },
        {
          featureType: "landscape.natural",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#7492a8",
            },
          ],
        },
        {
          featureType: "landscape.natural.terrain",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#dde2e3",
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "labels.icon",
          stylers: [
            {
              saturation: -100,
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#588ca4",
            },
          ],
        },
        {
          featureType: "poi.park",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#a9de83",
            },
          ],
        },
        {
          featureType: "poi.park",
          elementType: "geometry.stroke",
          stylers: [
            {
              color: "#bae6a1",
            },
          ],
        },
        {
          featureType: "poi.sports_complex",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#c6e8b3",
            },
          ],
        },
        {
          featureType: "poi.sports_complex",
          elementType: "geometry.stroke",
          stylers: [
            {
              color: "#bae6a1",
            },
          ],
        },
        {
          featureType: "road",
          elementType: "labels.icon",
          stylers: [
            {
              saturation: -45,
            },
            {
              lightness: 10,
            },
            {
              visibility: "on",
            },
          ],
        },
        {
          featureType: "road",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#41626b",
            },
          ],
        },
        {
          featureType: "road.arterial",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#ffffff",
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#c1d1d6",
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "geometry.stroke",
          stylers: [
            {
              color: "#a6b5bb",
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "labels.icon",
          stylers: [
            {
              visibility: "on",
            },
          ],
        },
        {
          featureType: "road.highway.controlled_access",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#9fb6bd",
            },
          ],
        },
        {
          featureType: "road.local",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#ffffff",
            },
          ],
        },
        {
          featureType: "transit",
          elementType: "labels.icon",
          stylers: [
            {
              saturation: -70,
            },
          ],
        },
        {
          featureType: "transit.line",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#b4cbd4",
            },
          ],
        },
        {
          featureType: "transit.line",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#588ca4",
            },
          ],
        },
        {
          featureType: "transit.station",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#008cb5",
            },
          ],
        },
        {
          featureType: "transit.station.airport",
          elementType: "geometry.fill",
          stylers: [
            {
              saturation: -100,
            },
            {
              lightness: -5,
            },
          ],
        },
        {
          featureType: "water",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#a6cbe3",
            },
          ],
        },
      ],
    }
    map = new google.maps.Map(map, mapOptions);
    const pinMarker = new google.maps.Marker({
      position: myLatlng,
      icon: {
        path: pinMarkerSVG,
        fillColor: "#000000",
        fillOpacity: 1,
        strokeColor: "#000000",
        scale: 2,
        anchor: {
          x: 5,
          y: 13,
        },
      },
      map: map,
    });

    const myLatlng2 = new google.maps.LatLng(trackingDetails.deliveryguylatlng.lat, trackingDetails.deliveryguylatlng.lng);
    const squareMarker = new google.maps.Marker({
      position: myLatlng2,
      icon: {
        path: squareMarkerSVG,
        fillColor: "#000000",
        fillOpacity: 1,
        strokeColor: "#000000",
      },
      map: map,
    });

    const liveTrackMarker = new google.maps.Marker({
      position: myLatlng2,
      icon: {
        path: squareMarkerSVG,
        fillColor: "#e91e63",
        fillOpacity: 1,
        strokeColor: "#e91e63",
      },
      map: map,
    });

    setMarkerRef(liveTrackMarker);
    
    // const contentString =
    // '<div class="info-window-content"><h2>Material Dashboard PRO React</h2>' +
    // "<p>A premium Admin for React, Material-UI, and React Hooks.</p></div>";

    // const infowindow = new google.maps.InfoWindow({
    //   content: contentString,
    // });
    // google.maps.event.addListener(pinMarker, "click", function () {
    //   infowindow.open(map, pinMarker);
    // });
    // google.maps.event.addListener(squareMarker, "click", function () {
    //   infowindow.open(map, squareMarker);
    // });

    // setLiveTrackMapRef(new google.maps.Marker({
    //   map: map,
    //   draggable: true
    // }));
    function autoRefresh(map, pathCoords) {
      var i, route;

      route = new google.maps.Polyline({
        path: [],
        geodesic: true,
        strokeColor: "#000000",
        strokeWeight: 5,
        editable: false,
        map: map,
      });

      for (i = 0; i < pathCoords.length; i++) {
        setTimeout(
          function (coords) {
            route.getPath().push(coords);
          },
          10 * i,
          pathCoords[i]
        );
      }
    }

    const directionsDisplay = new google.maps.DirectionsRenderer({
      map: map,
      suppressMarkers: true,
      // polylineOptions: {
      //   strokeWeight: 5,
      //   strokeColor: "#808080",
      // },
      preserveViewport: true,
    });

    let bounds = new google.maps.LatLngBounds();

    directionsService.route(
      {
        destination: {
          lat: trackingDetails.addresslatlng.lat,
          lng: trackingDetails.addresslatlng.lng,
        },
        origin: {
          lat: trackingDetails.deliveryguylatlng.lat,
          lng: trackingDetails.deliveryguylatlng.lng,
        },
        travelMode: "DRIVING",
      },
      (result, status) => {
        if (status == "OK") {
          // setLiveTrackMarkerRef(new google.maps.Marker({
          //   map: map
          // }));
          bounds.union(result.routes[0].bounds);
          map.fitBounds(bounds);

          var display = new google.maps.DirectionsRenderer({preserveViewport: true});

          display.setMap(map);
          directionsDisplay.setDirections(result);

          autoRefresh(map, result.routes[0].overview_path);
          setRemainingDistance(result.routes[0].legs[0].distance.text)
          setRemainingTime(result.routes[0].legs[0].duration.text)
        }
      }
    );
  },[trackingDetails])


 
  const brandingCard = () => {
    return (
      <>
        <MDAlert color="success">
        <MDBox >
        <Grid container>
          <Grid item xs={12}>
          <MDBox  display="flex" alignItems="center">
            <MDAvatar alt="Avatar" size='xl' variant="rounded" src={trackViewConfig?.brand?.logo}>
            </MDAvatar>
            
              <MDBox ml={2} mt={0.5} lineHeight={1.4} >
              <MDTypography variant="body2" color="white">
                {trackViewConfig?.brand?.website}{" "}
                <MDTypography  fontWeight="medium" color="white">
                  {trackViewConfig?.brand?.tagline} 
                </MDTypography>
              </MDTypography>
              </MDBox>
            </MDBox>
            
            </Grid>
          </Grid>
          
         </MDBox>
      
        </MDAlert>
        <br />
      </>

    )
  }
  const deliveryCompleted = () => {
    return (
      <>
        <MDBox mt={3}>
        
            <MDBox>
              {deliveryProof ? (<MDBox
                component="img"
                id={0}
                src={deliveryProof}
                alt="small image 1"
                borderRadius="lg"
                shadow="md"
                width="100%"
                
                minHeight="5rem"
                sx={{ cursor: "pointer", objectFit: "cover" }}
              />) : (<iframe src={giphyEmbedURL} frameBorder="0" className="giphy-embed" allowFullScreen></iframe>)}
              
            </MDBox>
            <MDBox pb={2} px={2} textAlign="center" lineHeight={1.25}>
            <MDTypography variant="h6">Delivery Completed </MDTypography>
            </MDBox>
          
      </MDBox>
      </>
    )
  }
  const livetrackCard = () => {
    return (
      <>
      <Card sx={{ height: "100%" }}>
      <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
        <MDTypography variant="h6" fontWeight="medium">
          Live Location
        </MDTypography>  
        {(deliveryStatus == 'completed' || !trackViewConfig?.call) ? null : (
        <MDButton variant="outlined" color="info" size="small" onClick={()=>{
          if(trackingDetails && trackingDetails.deliveryguyphone) {
            window.open(`tel:${trackingDetails.deliveryguyphone}`, '_self');
          }
        }}>
          Call
        </MDButton>)}
      </MDBox>
      <MDBox p={2}>
        <div
          style={{
            height: `calc(50vh)`,
            borderRadius: "6px",
            overflow: "hidden",
          }}
          ref={mapRef}
        >
        </div>
      </MDBox>
      
      </Card>
      <br />
      </>
      
    )
  }
  const trackActivity = () => {
    return;
  }

  const deliveryCompletedCard = () => {
    return (
      <>
      <Card sx={{ height: "100%" }}>
     
      <MDBox p={2}>
        {deliveryCompleted()}
      </MDBox>
      
      </Card>

      <br />
        
      </>
    )
  }
  const etaAndCallCards = () => {
    return (
      <>
      <MDBox mb={3}>
          <Grid container spacing={3}>
           
            <Grid
              item
              xs={12}
              sm={12}
              lg={12}
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
            >
              <MDBox width="100%" mr={{ xs: 0, sm: 3 }} mb={{ xs: 3, sm: 0 }}>
                <DefaultInfoCard
                  icon="timer_icon"
                  title="ETA"
                  description="Estimated Arrival Time"
                  value={`${remainingTime} | ${remainingDistance}`}
                />
              </MDBox>
              {/* <MDBox width="100%">
                <DefaultInfoCard
                  icon="call_icon"
                  title="Tip"
                  description="Call the delivery personnel"
                  value="2342342345"
                />
              </MDBox> */}
            </Grid>
          </Grid>
      </MDBox>
       
      </>
    )
  }

  const reviewCard = () => {
    return (
      <>
      
      <Card sx={{ height: "100%" }}>
      <MDBox pt={2} px={2} pb={3} display="flex" justifyContent="space-between" alignItems="center">
        <MDTypography variant="h6" fontWeight="medium">
          Delivery Experience
        </MDTypography>  
          {tipamount ? (<MDTypography variant="h6" fontWeight="medium">
          Tipped: {tipamount}
        </MDTypography> ): (
          <>
           {trackViewConfig?.tip ? (<MDButton variant="outlined" color="info" size="small" onClick={handleConfiguratorOpen}>
          Tip
        </MDButton>) : null}
          </>
        )}
      </MDBox>

      <MDBox pb={2} px={2} textAlign="center" lineHeight={1.25}>
        <Rating
          name="hover-feedback"
          value={deliveryReviewStars}
          precision={0.5}
          onChange={(event, newValue) => {
            setDeliveryReviewStars(newValue);
            Firebase.database()
            .ref(`/tracking/delivery/${trackID}/deliveryreview`)
            .set(newValue)
          }}
          onChangeActive={(event, newHover) => {
            setHover(newHover);
          }}
          size="large"
          disabled={recorded}
        />
        
        {deliveryReviewStars !== null && <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
        {labels[hover !== -1 ? hover : deliveryReviewStars]}
        </MDTypography>}
        

      </MDBox>

      {/* <MDBox p={2} justifyContent="space-between" alignItems="center">
      
       <MDButton variant="text" color="info" size="large" iconOnly >
              <Icon>arrow_forward</Icon>              
            </MDButton>
      </MDBox> */}
     
      </Card>
      <br />
      </>
    )
  }
  const invalidIDCard = () => {
    return (
      <>
        <Card>   
      <MDBox p={2}>
        <Grid container>

          <Grid item xs={10}>
          
            <MDBox lineHeight={0.5}  px={1}>
             
              <MDTypography variant="button" fontWeight="bold" color={"success"}>
                Invalid Livetrack ID
                
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={2}>
          </Grid>
          
        </Grid>
      </MDBox>
      </Card>
       <br />
      </>
    )
  }
  const discussCard = () => {
    return (
      <>
        <Card>   
      <MDBox p={2}>
        <Grid container>

          <Grid item xs={10}>
          <MDBox px={1} display="flex" justifyContent="space-between" alignItems="center">
            <MDTypography variant="h6" fontWeight="medium">
              Discuss
            </MDTypography>
            {/* <MDButton variant="text" color="info" size="large" style={{paddingTop: "10px"}}>
              <Icon size="xlarge">arrow_forward</Icon>
              
            </MDButton> */}
          </MDBox>
            <MDBox lineHeight={0.5}  px={1}>
             
              <MDTypography variant="button" fontWeight="bold" color={"success"}>
                Chat, Docs, Images & more ... &nbsp;
                
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={2}>
          <MDBox px={1} display="flex" justifyContent="space-between" alignItems="center">
          <Link to="/deliverynotes"  state={{ storeUiniqId: "storeUiniqId"}}>
            <MDButton variant="text" color="info" size="large" iconOnly >
              <Icon>arrow_forward</Icon>              
            </MDButton>
          </Link>
            
          </MDBox>
           
          </Grid>
          
        </Grid>
      </MDBox>
      </Card>
       <br />
      </>
    
    )
  }

  const upSell = () => {
    return ;
  }

  const q = ({ functions: { pxToRem } }) => ({
    width: pxToRem(46),
    height: pxToRem(46),
    minWidth: pxToRem(46),
    minHeight: pxToRem(46),
    mr: 1,
  });

  const productImages = () => {
    return (
      <>
      <Card>
        {trackingDetails?.images &&
          (
            <>
            <ImgsViewer
              imgs={trackingDetails?.images}
              isOpen={imgsViewer}
              onClose={closeImgsViewer}
              currImg={imgsViewerCurrent}
              onClickPrev={imgsViewerPrev}
              onClickNext={imgsViewerNext}
              zoomable={true}
              drag={true}
              scalable={true}
            />
            <MDBox
              component="img"
              src={trackingDetails?.images[0].src}
              alt="Product Image"
              shadow="lg"
              borderRadius="lg"
              width="100%"
              onClick={openImgsViewer}
            />
           
            </>
          )
        }
        
        
      </Card>
      <br />
      </>
   
    )
  }

  return (
    <DashboardLayout>
       {invalidTrackID ? invalidIDCard() : (<>
        {trackViewConfig?.brand && brandingCard()}
        {livetrackCard()}
        {trackActivity()}
        {deliveryStatus == 'completed' ? deliveryCompletedCard() : etaAndCallCards()}
        {reviewCard()}
        {trackViewConfig?.chat && discussCard()}
        {trackViewConfig?.images && productImages()}
       </>
        
       )}
       
      {/* <Footer /> */}
    </DashboardLayout>
  );
return (<></>);
}

export default Home;
